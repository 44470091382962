import React, { useContext } from "react";
import BeeList from "../components/Courses/BeeList/BeeList";
import BeeWho from "../components/Courses/BeeWho/BeeWho";
import WhyToJoin from "../components/Courses/WhyToJoin/WhyToJoin";
import PayCards from "../components/Courses/PayCards/PayCards";
import HowToJoin from "../components/Courses/HowToJoin/HowToJoin";
import WhoWeAre from "../components/Courses/WhoWeAre/WhoWeAre";
import { DataContext } from "../contexts/DataContext";
import useCheckMobileScreen from "../hooks/useCheckMobileScreen";
import { BPSizes } from "../commons/Theme";
import Faq from "../components/Faq/Faq";
import ContactFormCourses from "../components/ContactFormCourses/ContactFormCourses";
import Hero3BC from "../components/Hero3BC/Hero3BC";

const Courses = () => {
  const {
    pageLabels,
    currentLanguageSuffix,
    pageImages,
    // i18nLink,
    // labels,
  } = useContext(DataContext);

  const getImageByName = (name) => {
    return pageImages.find((e) => e.name === name);
  };
  const isMobile = useCheckMobileScreen(BPSizes.ipad);
  const BeeSection = pageLabels.beeList;
  const BeeWhoSection = pageLabels.beeWho;
  const WhyToJoinSection = pageLabels.whyToJoin;
  const PayCardsSection = pageLabels.payCardsChoice;
  const WhyToJoinTwoSection = pageLabels.whyToJoinTwo;
  const HowToJoinSection = pageLabels.howToJoin;
  const WhoWeAreSection = pageLabels.whoWeAre;
  const FaqSection = pageLabels.dataFaq.data;

  return (
    <div>
      <Hero3BC
        title = {pageLabels.hero.bigText}
        image = {pageLabels.hero.image}
        paragraph = {pageLabels.hero.paragraph}
        buttonText = {pageLabels.hero.ctaLabel}
        to = "#discover-more"
        titleBackground = "transparent"
        buttonVariant="orange"
        noNewsletter={true}
        lang={currentLanguageSuffix}
        allImages = {pageImages}
        isCourses
      />
      <BeeList
        beeImage={getImageByName(isMobile ? "bee-man-mbl" : "bee-man")}
        content={BeeSection}
      />
      <BeeWho
        beeImage={getImageByName("alveare")}
        content={BeeWhoSection}
      />
      <WhyToJoin
        content={WhyToJoinSection}
      />
      <PayCards
        content={PayCardsSection}
      />
      <HowToJoin
        content={HowToJoinSection}
        isMobile
      />
      <WhoWeAre
        beeImage={getImageByName("bee-landscape")}
        content={WhoWeAreSection}
      />
      <WhyToJoin
        content={WhyToJoinTwoSection}
      />
      <ContactFormCourses courses/>
      <Faq
        data={FaqSection}
        courses
      />
    </div>
  );
};

export default Courses;
