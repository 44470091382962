import React from "react";
import {
  StyledPayCardsTitle,
  PayCardsWrapper,
  WrapperCard,
  CardContent,
  CardHeader,
  StyledLabel,
  StyledTitleHeader,
  StyledPrice,
  TextWrapper,
  StyledParagraph,
  StyledParagraphLi,
  WrapperFeatures,
  WrapperList,
  StyledImageCheck,
  StyledDisclaimer,
  WrapperButton,
  StyledCta,
} from "./style.jsx";

export default ({ content }) => {

  return (
    <PayCardsWrapper >
      <StyledPayCardsTitle
        dangerouslySetInnerHTML={{ __html: content.title }}
      />
      <WrapperCard>
        {content.containsCard?.map((e) => (
          <CardContent key={e.id}>
            <CardHeader>
              <TextWrapper>
                <StyledLabel dangerouslySetInnerHTML={{ __html: e.preTitle }} />
                <StyledTitleHeader dangerouslySetInnerHTML={{ __html: e.title }} />
              </TextWrapper>
              <StyledPrice dangerouslySetInnerHTML={{ __html: e.price }} />
            </CardHeader>
            <StyledParagraph dangerouslySetInnerHTML={{ __html: e.text }} />
            <WrapperList>
              {e.features?.map((el) => (
                <WrapperFeatures key={el.id}>
                  {el.isIncluded ? (
                    <StyledImageCheck src={"/courses/images/check.png"} alt={"check"} />
                  ) : (
                    <StyledImageCheck src={"/courses/images/uncheck.png"} alt={"uncheck"} />
                  )}
                  <StyledParagraphLi 
                    isFeatured={el.isIncluded} 
                    dangerouslySetInnerHTML={{ __html: el.label }} 
                  />
                </WrapperFeatures>
              ))}
            </WrapperList>
            <WrapperButton>
              <StyledCta>
                <a href="#contactForm">{content.ctaLabel}</a>
              </StyledCta>
            </WrapperButton>
            <StyledDisclaimer dangerouslySetInnerHTML={{ __html: e.disclaimer }}/>
          </CardContent>
        ))}
      </WrapperCard>
    </PayCardsWrapper>
  );
};
