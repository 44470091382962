import React, { useContext } from "react";
import {
  StyledBeeSectionTitle,
  BeeWhoWrapper,
  WrapperContent,
  StyledBeeCta,
  StyledWrapperButton,
} from "./style.jsx";
import { DataContext } from "../../../contexts/DataContext";
import useCheckMobileScreen from "../../../hooks/useCheckMobileScreen";
import { BPSizes } from "../../../commons/Theme";
import SectionTwoCol from "../../SectionTwoCol/SectionTwoCol.jsx";

export default ({ content, beeImage }) => {
  const {
    pageImages,
  } = useContext(DataContext);
  const isMobile = useCheckMobileScreen(BPSizes.ipad);
  const getImageData = (image) => pageImages.find((e) => e.path === image.path);
  const imageData = getImageData(beeImage);
  console.log(isMobile);
  return (
    <BeeWhoWrapper >
      <WrapperContent>
        <StyledBeeSectionTitle
          dangerouslySetInnerHTML={{ __html: isMobile ? content.titleMbl : content.title }}
        />
        <SectionTwoCol
          id="edubox"
          ul={content?.paragraph?.map((e) => {
            return { text: e };
          })}
          image={isMobile ? "" : imageData.path}
          rightImage
          noPadding
        />
        <StyledWrapperButton >
          <StyledBeeCta >
            <a href="#contactForm">{content.ctaLabel}</a>
          </StyledBeeCta>
        </StyledWrapperButton>
      </WrapperContent>
    </BeeWhoWrapper>
  );
};
